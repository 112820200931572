import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => import("@/view/pages/dadosEmpresa.vue"),
                    meta: { title: 'Dashboard' }
                },


                {
                    path: "/createFuncionario",
                    name: "createFuncionario",
                    component: () => import("@/view/components/rh/createFuncionario.vue")
                },

                {
                    path: "/gerenFuncionarios",
                    name: "gerenFuncionarios",
                    component: () => import("@/view/components/rh/gerenFuncionario.vue")
                },
                {
                    path: "/permissoes",
                    name: "permissoes",
                    component: () => import("@/view/components/config/permissons/permissoes.vue")
                },
                {
                    path: "/createRule",
                    name: "createRule",
                    component: () => import("@/view/components/config/permissons/createRule")
                },



                /* ------------------------------ GERENCIAMENTO ----------------------------- */
                //#region GERENCIAMENTO

                ////EMPRESA////
                {
                    path: "/empresa",
                    name: "empresa",
                    component: () => import("@/view/components/gerenciamento/empresa/listaEmpresa.vue")
                },

                {
                    path: "/createEmpresa",
                    name: "createEmpresa",
                    component: () => import("@/view/components/gerenciamento/empresa/createEmpresa.vue")
                },

                ////FIM EMPRESA///
                //////FILIAL/////
                {
                    path: "/filial",
                    name: "filial",
                    component: () => import("@/view/components/gerenciamento/filial/listaFilial.vue")
                },
                {
                    path: "/createFilial",
                    name: "createFilial",
                    component: () => import("@/view/components/gerenciamento/filial/createFilial.vue")
                },
                ////FIM FILIAL///




             
                {
                    path: "/perfil",
                    name: "perfil",
                    component: () =>
                        import("@/view/components/config/perfil/listaPerfil.vue")
                },

                {
                    path: "/createPerfil",
                    name: "createPerfil",
                    component: () =>
                        import("@/view/components/config/perfil/createPerfil.vue")
                },
            
                {
                    path: "/tipoProduto",
                    name: "tipoProduto",
                    component: () =>
                        import("@/view/components/financeiro/tipoProduto/listaTipoProduto.vue")
                },

                {
                    path: "/createTipoProduto",
                    name: "createTipoProduto",
                    component: () =>
                        import("@/view/components/financeiro/tipoProduto/createTipoProduto.vue")
                },
                {
                    path: "/produto",
                    name: "produto",
                    component: () =>
                        import("@/view/components/financeiro/produto/listaProduto.vue")
                },

                {
                    path: "/createProduto",
                    name: "createProduto",
                    component: () =>
                        import("@/view/components/financeiro/produto/createProduto.vue")
                },

          



                {
                    path: "/avisos",
                    name: "avisos",
                    component: () =>
                        import("@/view/components/aviso/avisos.vue")
                },
                
                {
                    path: "/aviso",
                    name: "aviso",
                    component: () =>
                        import("@/view/components/aviso/listaAvisos.vue")
                },
                {
                    path: "/createAviso",
                    name: "createAviso",
                    component: () =>
                        import("@/view/components/aviso/createAviso.vue")
                },





                 
                {
                    path: "/sala",
                    name: "sala",
                    component: () =>
                        import ("@/view/components/config/sala/listaSala.vue")
                },

                {
                    path: "/createSala",
                    name: "createSala",
                    component: () =>
                        import ("@/view/components/config/sala/createSala.vue")
                },
                {
                    path: "/local",
                    name: "local",
                    component: () =>
                        import ("@/view/components/config/local/listaLocal.vue")
                },

                {
                    path: "/createLocal",
                    name: "createLocal",
                    component: () =>
                        import ("@/view/components/config/local/createLocal.vue")
                },
           
                {
                    path: "/setor",
                    name: "setor",
                    component: () =>
                        import ("@/view/components/config/setor/listaSetor.vue")
                },

                {
                    path: "/createSetor",
                    name: "createSetor",
                    component: () =>
                        import ("@/view/components/config/setor/createSetor.vue")
                },

                {
                    path: "/moeda",
                    name: "moeda",
                    component: () =>
                        import ("@/view/components/financeiro/moeda/listaMoeda.vue")
                },
                {
                    path: "/createMoeda",
                    name: "createMoeda",
                    component: () =>
                        import ("@/view/components/financeiro/moeda/createMoeda.vue")
                },
                {
                    path: "/cambio",
                    name: "cambio",
                    component: () =>
                        import ("@/view/components/financeiro/cambio/listaCambio.vue")
                },
                {
                    path: "/createCambio",
                    name: "createCambio",
                    component: () =>
                        import ("@/view/components/financeiro/cambio/createCambio.vue")
                },
                {
                    path: "/createModoPagamento",
                    name: "createModoPagamento",
                    component: () =>
                        import ("@/view/components/financeiro/pagamento/createModoPagamento.vue")
                },
                {
                    path: "/modoPagamento",
                    name: "modoPagamento",
                    component: () =>
                        import ("@/view/components/financeiro/pagamento/listaModoPagamento.vue")
                },
                {
                    path: "/createFormaPagamento",
                    name: "createFormaPagamento",
                    component: () =>
                        import ("@/view/components/financeiro/pagamento/createFormaPagamento.vue")
                },
                {
                    path: "/formaPagamento",
                    name: "formaPagamento",
                    component: () =>
                        import ("@/view/components/financeiro/pagamento/listaFormaPagamento.vue")
                },

                {
                    path: "/caixa",
                    name: "caixa",
                    component: () =>
                        import ("@/view/components/financeiro/caixa/listaCaixa.vue")
                },
                {
                    path: "/createCaixa",
                    name: "createCaixa",
                    component: () =>
                        import ("@/view/components/financeiro/caixa/createCaixa.vue")
                },

                {
                    path: "/finanPedidoRecebimento",
                    name: "finanPedidoRecebimento",
                    component: () =>
                        import ("@/view/components/financeiro/pedido-recebimento/listaFinanPedidoRecebimento.vue")
                },
                {
                    path: "/createFinanPedidoRecebimento",
                    name: "createFinanPedidoRecebimento",
                    component: () =>
                        import ("@/view/components/financeiro/pedido-recebimento/createFinanPedidoRecebimento.vue")
                },
                {
                    path: "/showFinanPedidoRecebimento/:pedido_id",
                    name: "showFinanPedidoRecebimento",
                    component: () => import ("@/view/components/financeiro/pedido-recebimento/showFinanPedidoRecebimento")
                },





                {
                    path: "/pedidoCompra",
                    name: "pedidoCompra",
                    component: () =>
                        import ("@/view/components/financeiro/pedido-compra/listaPedidoCompra.vue")
                },
                {
                    path: "/pedidoCompraUsuario/:usuario_id?",
                    name: "pedidoCompraUsuario",
                    component: () =>
                        import ("@/view/components/financeiro/pedido-compra/listaPedidoCompraFuncionario.vue")
                },
                {
                    path: "/createPedidoCompra",
                    name: "createPedidoCompra",
                    component: () =>
                        import ("@/view/components/financeiro/pedido-compra/createPedidoCompra.vue")
                },
                {
                    path: "/createFornecedor",
                    name: "createFornecedor",
                    component: () =>
                        import ("@/view/components/financeiro/fornecedor/createFornecedor.vue")
                },
                {
                    path: "/fornecedor",
                    name: "fornecedor",
                    component: () =>
                        import ("@/view/components/financeiro/fornecedor/listaFornecedor.vue")
                },
                {
                    path: "/finanContasReceber/:pedido_id",
                    name: "finanContasReceber",
                    component: () => import ("@/view/components/financeiro/listaFinanContasReceber.vue")
                },
                {
                    path: "/finanContasReceberCaixa/:caixa_id?",
                    name: "finanContasReceberCaixa",
                    component: () => import ("@/view/components/financeiro/listaFinanContasReceberall.vue")
                },
                {
                    path: "/finanContasPagar",
                    name: "finanContasPagar",
                    component: () => import ("@/view/components/financeiro/listaFinanContasPagar")
                },
                {
                    path: "/finanContasPagarCaixa/:caixa_id?",
                    name: "finanContasPagarCaixa",
                    component: () => import ("@/view/components/financeiro/listaFinanContasPagarall")
                },
                {
                    path: "/venda",
                    name: "venda",
                    component: () => import ("@/view/components/vendas/createPedidoProduto")
                },
                {
                    path: "/pedidosEspera",
                    name: "pedidosEspera",
                    component: () => import ("@/view/components/vendas/listaPedidosEspera")
                },
                {
                    path: "/pedidosPronto",
                    name: "pedidosPronto",
                    component: () => import ("@/view/components/financeiro/pedido-recebimento/listaFinanPedidoRecebimento")
                },

            ]

        },

        {
            path: "/",
            component: () => import("@/view/pages/auth/Login"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/auth/Login")
                },
                {
                    name: "register",
                    path: "/register",
                    component: () =>
                        import("@/view/pages/auth/Login")
                },


            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue"),
        },
        // {
        //     path: "/comprovante",
        //     name: "comprovante",
        //     component: () =>
        //         import ("@/view/components/financeiro/comprovante.vue")
        // },

    ]
});