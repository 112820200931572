import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";



////modulos sistema
import sistema from "./vuex/modulos/sistema";
import configFuncionario from "./vuex/modulos/configFuncionarios";
import permissoes from "./vuex/modulos/permissoes"
import configEmpresa from "./vuex/modulos/configEmpresa"
import configUsuarios from "./vuex/modulos/configUsuarios"
import financeiro from "./vuex/modulos/financeiro/financeiro"
import produto from "./vuex/modulos/produto"

import aviso from "./vuex/modulos/aviso"


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        htmlClass,
        config,
        breadcrumbs,
        profile,
        sistema,
        configFuncionario,
        permissoes,
        configEmpresa,
        configUsuarios,
        produto,
        aviso,
        financeiro,


    }
});