import ApiService from "@/core/services/api.service";


export const SET_MESSAGE_ALERT = 'setMensagem'

export const SET_AVISOS = 'setAvisos'
export const SET_AVISO = 'setAviso'

const actions = {

    //#region  aviso
    async create_aviso(context, value) {
        let message = await ApiService.post('aviso/', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async update_aviso(context, value) {
        let data = await ApiService.put('aviso/'+value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, data)

 
    },
    async listar_avisos(context, params) {
        await ApiService.get('aviso')
            .then(response => context.commit(SET_AVISOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_aviso(context, params) {
        await ApiService.get('aviso/'+params)
            .then(response => context.commit(SET_AVISO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

};
    const getters = {};

    const mutations = {
    
        [SET_AVISOS](state, value) {
            state.lista_avisos = value
        },
       
        [SET_AVISO](state,value){
            state.aviso = value
        },
      
    
    
        [SET_MESSAGE_ALERT](state, value) {
            console.log(value)
            let customMessage;
            if (typeof value.message === "object") {
              
              customMessage = value.message.msg;
            }
            state.mensagem_alert = {
              tipo: value.tipo,
              message: customMessage ? customMessage : value.message,
            };
          },
    };
    
    const state = {
    
        lista_avisos: [],
        mensagem_alert: "",
        aviso:[],
      
    
    };
    
    export default {
        namespaced:true,
        state,
        actions,
        mutations,
        getters
    };